import React, { Component } from "react";
import Payment from "../../components/payment/Payment";
import axios from "@/axios";
import { applyVueInReact } from "vuereact-combined";
import LoadingSpinner from "@/components/LoadingSpinner";

//TODO -> Payment not working.
class RelianceInsurance extends Component {
  LoadingSpinnerComp = applyVueInReact(LoadingSpinner);

  constructor() {
    super();
    this.state = {
      loading: true,
      message: "",
      reports: "",
      policyNo: "",
      dob: "",
      isNepDob: false,
    };
  }
  componentDidMount() {
    this.setState({ loading: false });
  }

  checkReliancePolicy = async (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    if (!this.state.policyNo) {
      this.setState({
        message: "Please enter your Policy number.",
        loading: false,
      });
      return;
    }
    if (!this.state.dob) {
      this.setState({
        message: "Please enter your date of birth.",
        loading: false,
      });
      return;
    }
    if (!/^\d{4}-\d{2}-\d{2}$/.test(this.state.dob)) {
      this.setState({
        message: "Invalid date provided.",
        loading: false,
      });
      return;
    }
    const data = await axios.post("api/v2/services/insurance/checkpolicy", {
      Dob: this.state.dob,
      PolicyNumber: this.state.policyNo,
      InsuranceName: "RelianceLifeInsurance",
      IsNepDob: this.state.isNepDob,
    });

    this.setState({ loading: false, message: "" });
    if (data.data && data.data.response && data.data.response.Code === "000") {
      this.setState({ reports: data.data.response });
    } else {
      this.setState({ message: data.data.response.Message });
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "isNepDob") {
      return this.setState((prev) => {
        return {
          [name]: !prev[name],
        };
      });
    }

    this.setState({ [name]: value });
  };

  render() {
    return (
      <div className="container">
        <div className="wallet-form-box card">
          <div className="card-body">
            <h5 className="card-title">Reliance Life Insurance Payment.</h5>
            <a href="/" className="btn btn-close">
              <i className="fa fa-times"></i>
            </a>
            {this.state.message && (
              <div className="form-group fade-animation">
                <div className="show-message">{this.state.message}</div>
              </div>
            )}
            <div className="row">
              <div className="col-md-7">
                {this.state.reports ? (
                  <div>
                    <div className="mt-4">
                      <div className="font-weight-bold">Policy Number:</div>
                      <div>{this.state.policyNo}</div>
                    </div>
                    <div className="mt-2">
                      <div className="font-weight-bold">Date of Birth</div>
                      <div>{this.state.dob}</div>
                    </div>
                    <div className="mt-2">
                      <div className="font-weight-bold">Name</div>
                      <div>{this.state.reports.Name}</div>
                    </div>
                    <div className="mt-2">
                      <div className="font-weight-bold">Due Date</div>
                      <div>{this.state.reports.DueDate}</div>
                    </div>
                    <div className="mt-2">
                      <div className="font-weight-bold">Premium</div>
                      <div>{this.state.reports.Premium} NPR</div>
                    </div>

                    <div className="mt-2">
                      <div className="font-weight-bold">Interest Accrued</div>
                      <div>{this.state.reports.InterestAccrued} NPR</div>
                    </div>
                    <div className="mt-2">
                      <div className="font-weight-bold">Bill Amount</div>
                      <div>{this.state.reports.BillAmount} NPR</div>
                    </div>
                    <div className="fade-animation">
                      <Payment
                        paymentUri="api/v4/insurance/payment"
                        paymentPayload={{
                          Amount: this.state.reports.BillAmount,
                          InsuranceName: "RelianceLifeInsurance",
                          PolicyNo: this.state.policyNo,
                          PolicyName: this.state.reports.Name,
                          Dob: this.state.dob,
                        }}
                        setMessage={(message) => {
                          this.setState({ message });
                        }}
                        validationBeforePayment={this.validationBeforePayment}
                        paymentPreviewDetail={{
                          "Customer Name": this.state.reports.Name,
                          "Total Amount to Pay":
                            "Rs. " + this.state.reports.BillAmount,
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <form>
                    <div className="form-group">
                      <label>Acceptance No:</label>
                      <input
                        className="form-control"
                        type="text"
                        name="policyNo"
                        placeholder="Type Acceptance No. and press enter."
                        value={this.state.acceptanceNo}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        DOB:
                        <span className="font-weight-lighter ml-1">
                          ( eg: 2054-01-01 )
                        </span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="dob"
                        placeholder="Enter Date of birth and press enter"
                        value={this.state.dob}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <div className="d-flex">
                        <label>Is Nepali Date of Birth</label>
                        <label className="custom-switch ml-2">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            name="isNepDob"
                            onClick={this.handleChange}
                          />
                          <span className="custom-control-label"></span>
                        </label>
                      </div>
                    </div>
                    <div className="form-group">
                      <button
                        className="btn btn-primary mt-3"
                        onClick={this.checkReliancePolicy}
                      >
                        Search
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
            <this.LoadingSpinnerComp loading={this.state.loading} />
          </div>
        </div>
      </div>
    );
  }
}
export default RelianceInsurance;
